<template>
  <div class="container-fluid mt-3">
<!-- TODO: backend 연동    -->
    <div class="card shadow-sm py-2 w-100">
      <div class="card-body p-4">
        <div class="d-flex align-items-center mb-2">
          <h5 class="font-weight-bold mb-0">
            {{ $t('ADMIN.SUPPLIER.SUPPLIER_INFORMATION') }}
          </h5>
        </div>
        <div class="d-flex justify-content-end">
          <div class="ml-1">
            <b-select v-model="selected">
              <b-select-option value="">{{ $t('ADMIN.COMMON.PLEASE_SELECT') }}</b-select-option>
              <b-select-option value="ho">hoho</b-select-option>
            </b-select>
          </div>
        </div>
      </div>
    </div>
    <div class="card shadow py-2 w-100" v-show="selected">
      <div class="card-body p-4">
        <div class="row">
          <div class="col-12 col-lg-3 mb-3 mb-lg-0">
            <b-list-group>
              <b-list-group-item button>{{ $t('ADMIN.SUPPLIER.BLACK_ID') }}</b-list-group-item>
              <b-list-group-item>{{ $t('ADMIN.SUPPLIER.SUMMARY') }}</b-list-group-item>
              <b-list-group-item>{{ $t('ADMIN.SUPPLIER.ETC_MENU') }}</b-list-group-item>
              <b-list-group-item>{{ $t('ADMIN.SUPPLIER.ETC_MENU') }}</b-list-group-item>
            </b-list-group>
          </div>
          <div class="col-12 col-lg-9 text-right">
            <div class="mb-2">
              <b-textarea rows="8">

              </b-textarea>
            </div>
            <div class="mb-2">
              {{ $t('ADMIN.SUPPLIER.TOTAL') }} {{ COUNT }} {{ $t('ADMIN.SUPPLIER.COUNT') }}
            </div>
            <div>
              <b-button variant="danger">{{ $t('ADMIN.SUPPLIER.SUBMIT') }}</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";

@Component
export default class AdminSupplier extends Vue {
  selected = ''
  COUNT = 1
}
</script>

<style scoped>

</style>
